import moment from 'moment';

import { 
  FB_IS_METRIC,
  FB_LAST_WAYPOINT_IMAGE,
  FB_LAST_WAYPOINT_BACKGROUND,
  FB_WAYPOINT_FILTER_PREFS, 
  COORDINATE_FORMAT 
} from './FirebaseAttributes';

export const SETTINGS_WAYPOINT_SORT_TYPE_NAME = 1;
export const SETTINGS_WAYPOINT_SORT_TYPE_DATE = 2;
export const SETTINGS_WAYPOINT_SORT_TYPE_LOCATION = 3;

export const FavoriteType = Object.freeze({"MAP":0, "WAYPOINT":1, "WMU":2, "TRACKED_WAYPOINT":3, "LEH":4, "AG_LEASE":5, "DRAWN_WAYPOINT":6}); //these match iOS and Firebase. Android has to translate them.

export const MapStartupType = Object.freeze({"STARTUP_ENTIRE_PROVICE":0, "STARTUP_ZOOM_TO_LOCATION":1, "STARTUP_MAP_REGION":2});

export const TileServiceType = Object.freeze({"None":0, "XYZ":1, "WMS":2, "TMS":3});



export var gSettings = null;  
export class Settings {

    constructor(settingsManager, provinceCode) {
        this.currentProvinceCode = provinceCode;
        this.settingsManager = settingsManager;

        
        //these variables can also be accessed with this['IsMetric'] for example. Use setPreference and getPreference to set and get them
        this.CurrentBaseMap_Web = null;
        this.DefaultMapStartupSelection = MapStartupType.STARTUP_ENTIRE_PROVICE;
        this.HighlightSelectedWMU = true;
        this.IsMetric = true;
        this.SelectedLEHRegion = -1;
        this.ShowCWDWaypoints = true;
        this.ShowCWDZones = true;
        this.ShowDirectionsForWaypoints = true;
        this.WaypointSortType = SETTINGS_WAYPOINT_SORT_TYPE_DATE;
        this.startupMapRegions = new Array(); 
        this.DefaultMapFavoriteID = "";
        this.purchasesWeb = [];
        this.purchasesIOS = [];
        this.purchasesAndroid = [];
        this.WpFilter = {};
        this.SeasonVisibility_Arch = true;
        this.SeasonVisibility_Muzz = true;
        this.SeasonVisibility_Trap = true;
        this.SeasonVisibility_Draw = true;
        this.SeasonVisibility_Closed = true;
        this.SeasonVisibility_Youth = true;

        this.provinceConfig = {};
        this.loadProvinceConfig();

        if(gSettings === null) {
          gSettings = this;
        }
    }

    // Feels dirty to keep currentProv as a global in settings, but its referenced so widely
    get provinceCode() {
      return this.currentProvinceCode;
    }

    get manager() {
        return this.settingsManager;
    }

    async loadProvinceConfig() {
       let path = `/res/provinces/${this.currentProvinceCode}/province`;
       let config = await import(/* webpackMode: "eager" */ path);
       this.provinceConfig = config;
    }

    
    addMapStartupRegion(mapRegion) {
        this.startupMapRegions.push(mapRegion);
        console.log("map region added!");

    }
    
    removeMapStartupRegionWithUUID(uuid) {
        for(var i = 0; i < this.startupMapRegions.length; i++) {
            var existingRegion = this.startupMapRegions[i];
            if(existingRegion.uuid === uuid) {
                this.startupMapRegions.splice(i, 1); 
                console.log("map region removed!");
                break;
            }
        }
    }

    setPreference(pref, val) {
        //console.log('set pref: ' + pref + ' to ' + val);
        this[pref] = val;
    }

    getPreference(pref) {
        // console.log('get pref: ' + pref + ' is ' + this[pref]);
        return this[pref];
    }

    updatePreference(pref, val) {
        // console.log('get pref: ' + pref + ' is ' + this[pref]);
        return this.settingsManager.updatePreference(pref, val);
    }

     //setShowMapLayer(mapLayer, bShow) {
    //     //this.setPreference(mapLayer.visibilityPreference, bShow); //this will already be set from firebase
    //     if(bShow) {
    //         gMap.showMapLayer(mapLayer);
    //     } else {
    //         gMap.hideMapLayer(mapLayer);
    //     }
   // }

    //not sure if we'll ever even need this
    getShowMapLayer(mapLayer) {
        if(mapLayer.layerRoot == 'leh') {
            return mapLayer.getIndex() == this.SelectedLEHRegion;
        }
        return this[mapLayer.visibilityPreference] == null ? mapLayer.visibilityDefault : this[mapLayer.visibilityPreference];
    }
    
    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }

    //sku will be ALL_MAPS for all maps promo code
    setProductPurchasedWeb(sku, purchaseID, purchased) {
        if(!this.isProductPurchasedWeb(sku) && purchased) {
            if(purchaseID != null && this.isNumeric(purchaseID)) {
                var expiry = new Date(purchaseID * 1000);
                var today = new Date();
                if(today.getTime() > expiry.getTime()) {
                    //expired!
                    console.log("SUBSCRIPTION HAS EXPIRED")
                    return;
                }
            } 

            this.purchasesWeb.push({"sku": sku, "purchase_id": purchaseID});
        }
        else if(!purchased) {
            var index = this.purchasesWeb.map(function(e) { return e.sku; }).indexOf(sku);
            if(index >= 0) {
                this.purchasesWeb.splice(index, 1);
            }
        }
    }

    setProductPurchasedIOS(sku, purchased) {
        if(!this.isProductPurchasedIOS(sku) && purchased) {
            this.purchasesIOS.push(sku);
        }
        else if(!purchased) {
            var index = this.purchasesIOS.indexOf(sku);
            if(index >= 0) {
                this.purchasesIOS.splice(index, 1);
            }
        }
    }

    setProductPurchasedAndroid(sku, purchased) {
        if(!this.isProductPurchasedAndroid(sku) && purchased) {
            this.purchasesAndroid.push(sku);
        }
        else if(!purchased) {
            var index = this.purchasesAndroid.indexOf(sku);
            if(index >= 0) {
                this.purchasesAndroid.splice(index, 1);
            }
        }
    }

    isProductPurchased(sku) {
        return this.isProductPurchasedWeb(sku) || this.isProductPurchasedIOS(sku) || this.isProductPurchasedAndroid(sku);
    }

    isProductPurchasedWeb(sku) {
        for(var p in this.purchasesWeb) {
            var purchase = this.purchasesWeb[p];
            if(Object.prototype.hasOwnProperty.call(purchase, "sku")) {
                var skuTemp = purchase["sku"];
                if(sku == skuTemp || skuTemp == 'all_maps' || skuTemp == this.provinceCode+'_all_maps') {
                    return true;
                }
            }
        }

        return false;
    }

    getProductWeb(sku) {
        for(var p in this.purchasesWeb) {
            var purchase = this.purchasesWeb[p];
            if(Object.prototype.hasOwnProperty.call(purchase, "sku")) {
                var skuTemp = purchase["sku"];
                if(sku == skuTemp) {
                    return purchase;
                }
            }
        }

        return {};
    }

    isProductPurchasedIOS(sku) {
        return (this.purchasesIOS.indexOf(sku) >= 0);
    }

    isProductPurchasedAndroid(sku) {
        return (this.purchasesAndroid.indexOf(sku) >= 0);
    }

    waypointShouldBeFilteredOut(wp) {
        var filter = this.getPreference(FB_WAYPOINT_FILTER_PREFS);

        if(filter && !this.isEmpty(filter)) {
            if(filter.inactivePinImages.includes(wp.pinImage)) {
                return true;
            }
            if(filter.inactiveBackgroundImages.includes(wp.pinBackground)) {
                return true;
            }

            var creationMoment = moment(wp.creationDate);
            if(filter.startDate && filter.endDate) {
                if(creationMoment.isAfter(filter.endDate) || creationMoment.isBefore(filter.startDate)) {
                    return true;
                }
            }
        }
        return false;
    }

    isEmpty(obj) {
        for(var key in obj) {
            if(Object.prototype.hasOwnProperty.call(obj, key))
                return false;
        }
        return true;
    }

    getMapLayerColor(mapLayer) {
        var color = this.getPreference(mapLayer.colorPreference);
        return color ? color : mapLayer.defaultColor;
    }

    setColorForMapLayer(hexStringColor, colorPreference) {
        this.setPreference(colorPreference, hexStringColor);
    }

    getColorForCounties() {
        var color = this.getPreference(this.colorPreferenceForCounties());
        return color ? color : this.defaultColorForCounties();
    }

    setColorForCounties(hexStringColor) {
        this.setPreference(this.colorPreferenceForCounties(), hexStringColor);
    }

    colorPreferenceForCounties() {
        return 'county_colorpreference'
    }

    defaultColorForCounties() {
        return '#0000FF'
    }

    useMetric() {
        return this.getPreference(FB_IS_METRIC);
    }

    getLastWaypointImage() {
        var lastWaypointImage = this.getPreference(FB_LAST_WAYPOINT_IMAGE);
        return lastWaypointImage == null ? "camp" : lastWaypointImage;
    }

    getLastWaypointBackground() {
        var lastWaypointBackground = this.getPreference(FB_LAST_WAYPOINT_BACKGROUND);
        return lastWaypointBackground == null ? "ihunter_pin_background_green" : lastWaypointBackground;
    }

    getCoordinateFormat() {
        return this.getPreference(COORDINATE_FORMAT) != null ? gSettings.getPreference(COORDINATE_FORMAT) : 0;
    }
}

export default Settings;
